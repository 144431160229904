const ListOfSkills = {
  'Frontend': {
    background: `Most of what I learned about Frontend development was self-taught. In order to make some small projects and university projects come to reality, I had to learn the basics of HTML & CSS before scaling to more complicated tools like React and Vue.js.`,
    contents: [
      {
        title: 'Tools',
        items: [
          'HTML & CSS',
          'React.js & React Native',
          'Vue.js',
          'ASP.NET Webforms',
          'Figma (for mockups)'
        ]
      }
    ]
  },
  'Backend': {
    background: `Similar to Front-end, most of what I learned about backend comes from the projects I did. My first exposure to server backend development started in my internship. 

    I started to truly understand server backend programming when I did a class project that required me to build a RESTful API that connected a Frontend app with an Postgres database.
    
    On my day to day job I am also required to do backend work in a .NET site.`,
    contents: [
      {
        title: 'Tools',
        items: [
          'Node.js', 'Express.js', '.NET'
        ]
      }
    ]
  },
  'Databases': {
    background: `My knowledge in relational databases is strong, at least at a junior level. I know the basics of store and retrieve data from relational databases as well as the theory behind database architecture (Normalization). How to make more advanced queries and how the lookup system works.
    
    My knowledge in non-relational databases is not at its peak yet. I must admit I do not have a lot of experience with them. But, I did learn about Amazon DynamoDB on a course I took. I know how the lookup system works, how to make queries and I can recognize the advantages of Non-relational databases over relational ones and vice versa.`,
    contents: [
      {
        title: 'Types',
        items: ['Relational databases', 'Key-Value databases']
      },
      {
        title: 'Tools',
        items: ['Postgres', 'T-SQL', 'Amazon DynamoDB']
      }
    ]
  },
  'Cloud technologies': {
    background: `The Amazon course Modern Application development on AWS opened my eyes to the power of the Cloud.
    
    I understood the many advantages the Cloud has over in-house servers, as well as it many tools that can simplify developers' life while making applications hyper scallable. *insert unlimited power meme*`,
    contents: [
      {
        title: 'Tools',
        items: ['Amazon EC2', 'Lambda Functions', 'API Gateway', 'DynamoDB', 'VPCs', 'Load balancers', 'Many others!']
      }
    ]
  },
  'Software Architecture': {
    background: `Most of my projects relied heavily on Object Oriented Programming and the MVC architecture. There were a few times when I had to change gears and use more uncommon approaches like Functional Programming and such.
    
    Other than just programming, I know about diagramming Software Systems too. I am familiar with common diagramming techniques such as UML and Entity Relationships.`,
    contents: [
      {
        title: 'Concepts',
        items: ['Object Oriented programming', 'Functional programming', 'MVC architecture', 'UML diagramming']
      }
    ]
  },
  'Algorithm Analysis': {
    background: `Algorithms analysis is a key topic throughout a Computer Science. Almost everything I know about algorithms is thanks to my classes. We went through logical analysis of algorithms to evaluating their efficiency (Big O). 

    We also did analyze common problems and different approaches the different approaches we can take to solve them from brute force to dynamic programming. Looking at how each approach can affect their time and space complexities and even how trading off the optimal solution can lead to significant improvements in those areas.`,
    contents: []
  },
  'Software Testing': {
    background: `Throughout my career at K-State, most of homework and projects were controlled using Unit testing and similar. That plus the fact that I took a Software Testing class teached me the foundations (and the quirks) of Software Testing
    
    To my surprise Unit Testing and Integration Testing weren’t THE only way to test software.
    
    Yet, the true value of testing was revealed to me on my first job.`,
    contents: [
      {
        title: 'Tools',
        items: ['NUnit', 'XCTest & XCTest UI']
      },
      {
        title: 'Concepts',
        items: ['Unit testing', 'Integration testing', 'Functional testing', 'Performance testing', 'Document testing', 'Test driven development']
      }
    ]
  },
  'Concurrent Programming': {
    background: `I never truly understood how parallelism worked in software until I took an Operating System class. 

    Yet, where I learned everything about Distributed Systems applications was on a university class. We learned how multithreading works and how to code it using various libraries. We also learned how to work with multiple processes. Thanks to Beocat (K-State Computer Cluster) we were able to take test our programs using more than 60 cores at a time distributed over many computers. It was amazing!`,
    contents: [
      {
        title: 'Tools',
        items: ['OpenMP', 'Pthreads', 'MPI']
      }
    ]
  },
  'Data Analytics & Data Science': {
    background: `I am fascinated by how systems nowadays manage data. That is why I have a special interest in Data Analytics and Data Science.
    
    I took the Google Certicate in Data Analytics that introduced me to many useful concepts and techniques.
    
    Additionally, I took an AI class in university that showed me algorithms used in Artificial Intelligence problems, as well as other interesting stuff like Machine Learning which totally got me hooked up with Data Science.`,
    contents: [
      {
        title: 'Tools',
        items: ['R', 'Tensorflow', 'Pandas', 'Numpy']
      }
    ]
  },
  'People Skills': {
    background: `I am known to be an easy-going and reasonable person by my family and friends. Meaning I don’t usually crumble at a lot of pressure and I’m usually in a good humor to deal with people. That makes me really good for team projects as I can always be calm in hard situations and communicate with people effectively and respectfully regardless of the team’s vibes.`,
    contents: [
      {
        title: 'Languages',
        items: ['English (Daily use)', 'Spanish (Native)', 'German (Basic)']
      }
    ]
  }
}

export default ListOfSkills
