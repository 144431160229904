import * as React from 'react'

import './contact.css'

function LinkedInIcon(props) {
  return (
    <a href="https://www.linkedin.com/in/santiago-scavone-pereira-a026b4214/">
      <svg id="linkedin-icon" width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
        <path className="linkedin-i" d="M0.847241 25.4517H16.9935V77.386H0.847241V25.4517ZM8.92083 -0.364075C14.0814 -0.364075 18.2752 3.82979 18.2752 8.999C18.2752 14.163 14.0814 18.3569 8.92083 18.3569C3.74816 18.3569 -0.433594 14.163 -0.433594 8.999C-0.433594 3.82979 3.74816 -0.364075 8.92083 -0.364075Z" fill="#C5C5C5"/>
        <path className="linkedin-n" d="M27.1172 25.4517H42.5997V32.5534H42.8204C44.9753 28.4686 50.2423 24.1656 58.0926 24.1656C74.438 24.1656 77.4548 34.9186 77.4548 48.9022V77.386H61.3207V52.1302C61.3207 46.106 61.2159 38.3595 52.9329 38.3595C44.5331 38.3595 43.2514 44.9255 43.2514 51.6975V77.386H27.1172V25.4517Z" fill="#C5C5C5"/>
        </g>
      </svg>
    </a>
  )
}

function GoogleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="52 42 88 66" id="gmail-icon">
      <path className="gmail-left-bottom" fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6"/>
      <path className="gmail-right-bottom" fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15"/>
      <path className="gmail-right-top" fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2"/>
      <path className="gmail-middle" fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92"/>
      <path className="gmail-left-top" fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"/>
    </svg>
  )
}

export default function Contact(props) {
  const TEXT = `Let's connect!`

  //Rerenders when going in or out of Desktop mode
  const [isPhone, setPhone] = React.useState(true)
  React.useEffect(() => {
    window.addEventListener('resize', () => setPhone(window.innerWidth < props.desktopAt))
  })
  React.useEffect(() => {setPhone(window.innerWidth < props.desktopAt)}, [props.desktopAt])

  if (isPhone) {
    return(
      <div id="contact-container" style={{flexDirection: 'column'}}>
          <div>{TEXT}</div>
          <div style={{width: '100%', justifyContent: 'space-around'}}>
            <LinkedInIcon/>
          </div>
        </div>
    )
  }

  return (
    <div id="contact-container">
      <div>{TEXT}</div>
      <LinkedInIcon/>
    </div>
  )
}
