import * as React from 'react'

export function TextToComponent(props) {
  const Paragraphs = props.text.split('\n').map((par, index) =>
    <p key={index}>
      {par}
    </p>
  )

  return (
    <div>
      {Paragraphs}
    </div>
  )
}

 /**
  * Div that is constantly checking for window resize events.
  * If there is a resize, the div fires an event with the new height.
  * 
  * @param {*} props 
  * @returns div that triggers an event every time its height changes
  */
export function DivHeightMeasurer(props) {
  const [, setDimensions] = React.useState({
    height: 0,
    width: 0
  })
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    function handleWindowChange() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', () => handleWindowChange)

    return function cleanup() {
      window.removeEventListener('resize', () => handleWindowChange)
    }
  }, [])

  return (
    <div className={props.className} ref={ (element) => {
      if(props.name === "Frontend") console.log("Trying to update")
      if (element) {
        setHeight(element.clientHeight)
        props.setBodyHeight(element.clientHeight)
      }
    }}>
      {props.children}
    </div>
  )
}
