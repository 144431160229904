import * as React from 'react'

import './single-experience-desktop.css'
import './single-experience-phone.css'

import PlusSVG from '../../assets/add.svg'
import MinusSVG from '../../assets/remove.svg'

import { DivHeightMeasurer } from '../../helper.js'

function DesktopExp(props){
  return (
    <div className="desk-experience-single-container">
      <div className="desk-experience-single-info">
        <h2>{props.title}</h2>
        <h5>{props.location}</h5>
        <h6>{props.date}</h6>
      </div>
      <div className="desk-experience-single-text">
        {props.children}
      </div>
    </div>
  )
}

function PhoneExp(props) {
  const [isOpen, setOpen] = React.useState(false)
  const [bodyHeight, setBodyHeight] = React.useState(0)

  const PlusMinus = () => {
    if (isOpen) {
      return <MinusSVG/>
    }
    else return <PlusSVG/>
  }
  const renderBodyHeight = isOpen ? bodyHeight + 10 : '0px'
  const bodyOpacity = isOpen ? '1' : '0'

  return (
    <div className="phone-experience-single-container">
        <div className="phone-experience-single-top-container" role="button" onClick={() => setOpen(!isOpen)} onKeyDown={() => setOpen(!isOpen)}>
          <h2>{props.title}</h2>
          <PlusMinus/>
        </div>
        <div className="phone-experience-single-body-dropdown" style={{height: renderBodyHeight, opacity: bodyOpacity}}>
          <DivHeightMeasurer {...props} setBodyHeight={setBodyHeight} className="phone-experience-single-body" name={props.title}>
            <h5>{props.location}</h5>
            <h6>{props.date}</h6>
            <div>
              {props.children}
            </div>
          </DivHeightMeasurer>
        </div>
    </div>
  )
}

export { PhoneExp, DesktopExp }
