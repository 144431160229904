import * as React from 'react'
import { DivHeightMeasurer, TextToComponent } from '../../helper'

//SVGs
import DoubleArrow from '../../assets/double-arrow.svg'
import FrontendSVG from '../../assets/skills-svg/Frontend.svg'
import BackendSVG from '../../assets/skills-svg/Backend2.svg'
import DatabasesSVG from '../../assets/skills-svg/Database2.svg'
import CloudSVG from '../../assets/skills-svg/Cloud.svg'
import DesignSVG from '../../assets/skills-svg/Design.svg'
import AlgorithmSVG from '../../assets/skills-svg/Algorithm.svg'
import TestingSVG from '../../assets/skills-svg/testing.svg'
import ConcurrentSVG from '../../assets/skills-svg/Concurrent.svg'
import ArtificialSVG from '../../assets/skills-svg/Artificial-Intelligence.svg'
import PeopleSVG from '../../assets/skills-svg/people.svg'

import './skills-desktop.css'
import './skills-phone.css'

function Skill(props) {
  if(props.mode === 'desk') {
    return <SkillDesktop {...props}/>
  }
  else if (props.mode === 'phone') {
    return <SkillPhone {...props}/>
  }

  return (null)
}

function SkillDesktop(props) {
  const Contents = props.contents.map((content) => <ContentList {...content} mode={props.mode} key={content.title}/>)

  const ContainerCSSClass = `${props.mode}-skill-single-container`

  return (
    <div className={ContainerCSSClass}>
      {props.children}
      <BackgroundContent mode={props.mode} text={props.background}/>
      {Contents}
    </div>
  )
}

function SkillPhone(props) {
  const [isOpen, setOpen] = React.useState(false)
  const [bodyHeight, setBodyHeight] = React.useState(0)
  const arrowDeg = isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'
  const renderBodyHeight = isOpen ? bodyHeight + 10 : '0px'
  const renderBodyOpacity = isOpen ? '1' : '0'

  const Contents = props.contents.map((content) => <ContentList {...content} mode={props.mode} key={content.title}/>)

  return(
    <div className="phone-skill-single-container">
      <div className="phone-skill-single-top-container" onClick={() => setOpen(!isOpen)}>
        <h2>{props.skill}</h2>
        <div className="phone-skill-single-top-svg-container" style={{transform: arrowDeg}}>
          <DoubleArrow/>
        </div>
      </div>
      <div className="phone-skill-single-body-dropdown" style={{height: renderBodyHeight, opacity: renderBodyOpacity}}>
        <DivHeightMeasurer {...props} setBodyHeight={setBodyHeight} className="phone-skill-single-info-container" name={props.skill}>
          {props.children}
          <BackgroundContent mode={props.mode} text={props.background}/>
          {Contents}
        </DivHeightMeasurer>
      </div>
    </div>
  )
}

function ContentList(props) {
  const Content = props.items.map((item, index) => <li key={index}>{item}</li>)

  const ContainerCSSClass = `${props.mode}-skill-single-others-container`
  const ListCSSClass = `${props.mode}-skill-content-list`

  return (
    <div className={ContainerCSSClass}>
      <h2>{props.title}</h2>
      <ul className={ListCSSClass}>
        {Content}
      </ul>
    </div>
  )
}

function BackgroundContent(props) {
  const ContainerCSSClass = `${props.mode}-skill-single-background-container`

  return (
    <div className={ContainerCSSClass}>
      <h2>Background</h2>
      <TextToComponent text={props.text}/>
    </div>
  )
}

function SkillSvg(props) {
  const ContainerCSSClass = `${props.mode}-skill-svg-container`

  const Svg = () => {
    switch(props.skill) {
      case 'Frontend':
        return <FrontendSVG/>
      case 'Backend':
        return <BackendSVG/>
      case 'Databases':
        return <DatabasesSVG/>
      case 'Cloud technologies':
        return <CloudSVG/>
      case 'Software Architecture':
        return <DesignSVG/>
      case 'Algorithm Analysis':
        return <AlgorithmSVG style={{}}/>
      case 'Software Testing':
        return <TestingSVG style={{stroke: '#ffd6ba'}}/>
      case 'Concurrent Programming':
        return <ConcurrentSVG/>
      case 'Data Analytics & Data Science':
        return <ArtificialSVG/>
      case 'People Skills':
        return <PeopleSVG style={{}}/>
      default:
        return (null)
    }
  }

  if(props.mode === 'phone') {
    return <div className={ContainerCSSClass}>
        <Svg/>
      </div>
  }

  return <div className={ContainerCSSClass}>
    <Svg/>
    <h2>{props.skill}</h2>
  </div>
}

class Skills extends React.Component {
  constructor(props) {
    super(props)
    this.data = props['data']
    this.desktopAt = props['desktopAt']
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    const mode = this.state.width > this.desktopAt ? "desk" : "phone"
    const ContainerCSSClass = `${mode}-skills-container`

    const AllSkills = Object.keys(this.data).map((skillName, index) =>
    <Skill {...this.data[skillName]} mode={mode} skill={skillName} key={index}>
      <SkillSvg skill={skillName} mode={mode}/>
    </Skill>)

    return (
      <div className={ContainerCSSClass}>
        <h1>Skills</h1>
        {AllSkills}
      </div>
    )
  }
}

export default Skills