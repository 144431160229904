import * as React from "react"
import { TextToComponent } from "../../helper"

import './about.css'

function AboutMe(props){

  return (
    <div id="aboutme-container">
      <h1>About Me</h1>
      <TextToComponent text={props.text}/>
    </div>
  )
}

export default AboutMe