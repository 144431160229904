import * as React from "react"

import "./picture.css"

function MyPicture() {
  return (
    <div id="picture-container">
      <div id="picture-label-container">
        <h2>Hello,</h2>
        <p>I am <b>Santiago Scavone</b></p>
      </div>
      <div id="effect"></div>
    </div>
  )
}

export default MyPicture
