import * as React from "react"
import { TextToComponent } from "../../helper"
import { DesktopExp, PhoneExp } from "./single-experience"

import './experiences.css'

class Experience extends React.Component {
  constructor(props) {
    super(props)
    this.data = props['data']
    this.desktopAt = props['desktopAt']
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    let Items;

    //Desktop window size
    if (this.state.width > this.desktopAt) {
      Items = this.data.map((exp) =>
        <DesktopExp {...exp} key={exp.title}>
          <TextToComponent text={exp.text} />
        </DesktopExp>
      )
    }
    //Phone window size
    else {
      Items = this.data.map((exp) =>
        <PhoneExp {...exp} key={exp.title}>
          <TextToComponent text={exp.text}/>
        </PhoneExp>
      )
    }

    return (
      <div id="experience-page-container">
        <h1>Experiences</h1>
        {Items}
      </div>
    )
  }
}

export default Experience
