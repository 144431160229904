import * as React from "react"

import MyPicture from "../components/picture/picture"
import AboutMe from "../components/aboutme/aboutme"
import Experience from "../components/experience/experience"
import Skills from "../components/skills/skills"
import Contact from "../components/contact/contact"

//Fonts
import "@fontsource/roboto"
import "@fontsource/modak"
import "@fontsource/merriweather"
import "@fontsource/cookie"

import ListOfExperiences from "../assets/contentlists/list_of_experiences"
import ListOfSkills from "../assets/contentlists/list_of_skills"
import AboutMeText from "../assets/contentlists/aboutme_text.js"

//Width (in px) at which a page turns to desktop mode
const EXPERIENCE_DESKTOP_WIDTH = 1100;
const SKILLS_DESKTOP_WIDTH = 1300;
const CONTACT_DESKTOP_WIDTH = 600;

class IndexPage extends React.Component {
  componentDidMount() {
    document.body.style.margin = 0;
    document.title = "Santiago Scavone";
  }

  render() {
    return (
      <main>
        <MyPicture/>
        <AboutMe text={AboutMeText}/>
        <Experience data={ListOfExperiences} desktopAt={EXPERIENCE_DESKTOP_WIDTH}/>
        <Skills data={ListOfSkills} desktopAt={SKILLS_DESKTOP_WIDTH}/>
        <Contact desktopAt={CONTACT_DESKTOP_WIDTH}/>
      </main>
    )
  }
}

export default IndexPage
