const ListOfExperiences = [
  {
    title: 'Computer Science Degree',
    location: 'Kansas State University',
    date: '08/2017 - 05/2021',
    text: `Going to K-State to study Computer Science was a great experience in many ways.  Not only did I learn a lot of things (my skill section would not have been what it is otherwise) and experienced many challenges that helped me grow professionally and personally, but also I had a great time doing so! 

          My career at K-State was mostly focused on general Computer Science concepts instead of a single area. We started with basic topics like Data Structures and Logic to more advanced ones such as Algorithm Analysis and Distributed Systems. 
    
          All in all, I met people that are now friends for life and learned a few lessons I will never forget.`
  },
  {
    title: 'TA & Math Grader',
    location: 'Kansas State University',
    date: '08/2019 - 05/2020',
    text: `People say you only really understand something once you are able to explain it to your grandma... or to some random college students.
    
          In my experience there is some truth to that saying.
          
          I was a TA in a Data Structures class and a Math grader in Discrete Mathematics. In both cases, I understood the subjects I had to grade or teach much more deeply than I did when I was the one taking those clases.`
  },
  {
    title: 'Backend developer',
    location: 'Konecta',
    date: '07/2020 - 08/2020',
    text: `Although brief this was a very important experience for my career.

          It was my first exposure to a real life scenario of a relatively big software project. I was able to experience how it felt to work in teams. Things like documenting everything you do were new to me.
        
          I must admit though, that I only truly understood everything that was going on after I was finished with the internship. Postman is a good example of this, I knew what I should be getting, but not why or how.

          Now I finally understand what Postman acomplishes and why developers use it at all.

          Even with all my confusions as a newbie, I did well.`
  },
  {
    title: '.NET Developer',
    location: `Kansas' Department of Health`,
    date: '08/2021 - Present',
    text: `My first "real" job. Quite the experience it was (and still is).

          To summarize, I worked in the Kansas Grant Managment System that manages grants.

          The system was already in place but it needed new features and needed tweaks on the stuff that was already out there.

          That's where I come in. I work with the main developer to release these build these new features and I also talk with the stakeholders to get the requirements right.

          It is fair to say that the developer side doesn't always align with the stakeholder side. But we manage to make it work everytime!
    
          As for the technical side, I was basically a Full Stack developer. Using ASP.NET to build both the frontend and backend.`
  }
]

export default ListOfExperiences
